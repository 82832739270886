(function ($) {
  // Document ready
  $(function () {
    $(window).on("scroll", function () {
      stickHeader();
    });

    stickHeader();

    var startHeader = null;
    var totalHeader = null;
    function stickHeader() {
      const el = $(".main-header")[0];

      if (!el) {
        return;
      }

      if (startHeader === null) {
        startHeader = window.pageYOffset + el.getBoundingClientRect().top;
      }
      if (totalHeader === null) {
        totalHeader = window.pageYOffset + el.getBoundingClientRect().bottom;
      }

      if (
        $(window).scrollTop() > startHeader &&
        !$(".main-header").hasClass("main-header--fixed")
      ) {
        $(".main-header").addClass("main-header--fixed");
        $("body").css("padding-top", $(".main-header").outerHeight());
      }

      if (
        $(".main-header").hasClass("main-header--fixed") &&
        totalHeader !== null &&
        $(window).scrollTop() <= startHeader
      ) {
        $(".main-header").removeClass("main-header--fixed");
        $("body").css("padding-top", 0);
      }
    }
  });
})(jQuery);
